import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const Careers = () => {
  return (
    <Layout>
      <SEO title="SCE Employment Opportunities" />

      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="py-24 w-full px-4 md:px-0 md:w-3/5 mx-auto">
        <div className="border-b border-gray-800 mb-8 pb-8">
          <h1 className="uppercase font-bold tracking-wide mb-4 text-5xl">
            Employment Opportunities
          </h1>
        </div>
        <article className="text-xl">
          <p className="mb-6">Southeastern Consulting Engineers has multiple openings for an Associate Engineer based out of its location in Charlotte, NC.</p>
          <p className="mb-6"><span className="font-bold">Job Duties:</span>  Provide engineering support for the municipal electric system and water/wastewater electrical distribution systems. Apply electrical engineering principles to design overhead and underground power distribution systems. Perform distribution planning studies, DER impact study. Design relay protection schemes for substations and water/wastewater treatment plants. Perform relay coordination studies, fault current analysis and arc flash studies. Prepare construction documents, such as drawings and specifications, for power system projects utilizing AutoCAD. Perform bid evaluation and provide cost estimates for projects. Collect relevant engineering data in the field, as needed. Position requires travel up to 20% of time.</p>
          <p className="mb-6"><span className="font-bold">Requirements:</span>  Bachelor's degree in electrical engineering or related field and four months of experience in electrical engineering, and demonstrated experience and/or knowledge in:  Fault Analysis, sequence networks, and protective device coordination; One Line and Three Line Diagrams; Quantification and monetization of benefits of electrical distribution systems; Operation of electrical devices like fuses, reclosers, sectionalizers, transformers, circuit breakers, generators, voltage regulators; Power Flow Analysis, Power System Reliability Analysis and Per Unit Calculations; Distribution Planning, Voltage Drop Study, Volt/VAR Control Study and Power Factor Improvement; Engineering Economics, Lifetime cost evaluation, Cost Benefit Analysis; Impact of DER penetration in electrical distribution system; NEC, NFPA, NFPA 70E, NESC codes; Software: MATLAB and OpenDSS; Completion of FE exam or ability to do so within 1 year of hire.</p>
          <p className="mb-6">Send resume to Southeastern Consulting Engineers, Attn: Mike Dougherty, 600 Minuet Ln., Charlotte, NC 28217</p>
        </article>
      </div>
    </Layout>
  )
}

export default Careers
